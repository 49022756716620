import { GatewayDepositSettingActionTypes } from './constants';

const INIT_STATE = {
    error: {},
    validationErrors: {},
    loading: {},
    trackIds: [],
    settingUpdated: false,
    options: {},
};

type GatewayDepositSettingData = {
    id: number;
    name: string;
};

type GatewayDepositSettingActionType = {
    type:
        | GatewayDepositSettingActionTypes.API_RESPONSE_SUCCESS
        | GatewayDepositSettingActionTypes.API_RESPONSE_ERROR
        | GatewayDepositSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | GatewayDepositSettingActionTypes.RESET
        | GatewayDepositSettingActionTypes.UPDATE_SETTING
        | GatewayDepositSettingActionTypes.GET_OPTIONS;

    payload: {
        actionType?: string;
        data?: GatewayDepositSettingData | any;
        error?: string;
        validationErrors?: [];

        key: string;
        value?: string;

        page?: string;

        trackId: string
    };
};

type State = {
    loading: {},
    error: {},
    trackIds: string[]
};

const GatewayDepositSetting = (state: State = INIT_STATE, action: GatewayDepositSettingActionType) => {
    switch (action.type) {
        case GatewayDepositSettingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case GatewayDepositSettingActionTypes.UPDATE_SETTING: {
                    return {
                        ...state,
                        settingUpdated: action.payload.data.result,
                        loading: {
                            updateSetting: false
                        },
                        trackIds: state.trackIds.filter(e => e !== action.payload.data.trackId)
                    };
                }
                case GatewayDepositSettingActionTypes.GET_OPTIONS: {
                    return {
                        ...state,
                        options: action.payload.data,
                        loading: {
                            options: false
                        }
                    };
                }
                default:
                    return { ...state };
            }

        case GatewayDepositSettingActionTypes.API_RESPONSE_ERROR:

            switch (action.payload.actionType) {

                case GatewayDepositSettingActionTypes.UPDATE_SETTING:
                {
                    return {
                        ...state,
                        error: {
                            "updateSetting": action.payload.error
                        },
                        loading: {
                            "updateSetting": false
                        },
                        trackIds: state.trackIds.filter(e => e !== action.payload.trackId)
                    };
                }
                case GatewayDepositSettingActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        error: {
                            "options": action.payload.error
                        },
                        loading: {
                            "options": false
                        }
                    };
                }

                default:
                    return { ...state };
            }

        case GatewayDepositSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case GatewayDepositSettingActionTypes.UPDATE_SETTING:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "updateSetting": action.payload.validationErrors
                        },
                        loading: {
                            "updateSetting": false
                        },
                    };
                }
                case GatewayDepositSettingActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        validationErrors: {
                            "options": action.payload.validationErrors
                        },
                        loading: {
                            "options": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case GatewayDepositSettingActionTypes.UPDATE_SETTING:
            const { updateSetting, ...remainingErrorsForUpdateS } = state.error as { [key: string]: any };
            return {
                ...state,
                settingUpdated: false,
                loading: {
                    updateSetting: true
                },
                error: remainingErrorsForUpdateS,
            };

        case GatewayDepositSettingActionTypes.GET_OPTIONS:
            const { options, ...remainingErrorsForGetSetting } = state.error as { [key: string]: any };
            return {
                ...state,
                options: {},
                loading: {
                    options: true
                },
                error: remainingErrorsForGetSetting,
            };

        case GatewayDepositSettingActionTypes.RESET:

            return {
                ...state,
                error: {},
                loading: {},
                options: {}
            };

        default:
            return { ...state };
    }
};

export default GatewayDepositSetting;

export enum GatewayDepositSettingActionTypes {
    API_RESPONSE_SUCCESS = '@@gatewayDepositSetting/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@gatewayDepositSetting/API_RESPONSE_ERROR',
    API_RESPONSE_VALIDATION_ERRORS = '@@gatewayDepositSetting/API_RESPONSE_VALIDATION_ERRORS',

    RESET = '@@gatewayDepositSetting/RESET',

    GET_OPTIONS = '@@gatewayDepositSetting/GET_OPTIONS',
    UPDATE_SETTING = '@@gatewayDepositSetting/UPDATE_SETTING',
}

export enum GatewayDepositSettingErrors {
    RESPONSE_200 = 'Unfortunately, an error has occurred. Please try again.'
}




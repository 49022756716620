import { DepositActionTypes } from './constants';
import {isEmptyObject} from "../../helpers/functions";

const INIT_STATE = {
    deposits: {},
    queryParams: {},

    deposit: {},

    error: {},
    validationErrors: {},
    loading: {},

    depositInsertedUrl: "",
    depositInserted: false,
    transactionVerified: false,

    depositUpdated: false,

    insertedDepositId: "",

    searchModal: false,
    tmpDeposits: {},
    tmpQueryParams: {},
    searchMode: false,

    exportUrl: "",


    gateways: [],

    totalDeposits: {},

    insertedOfflineDepositId: null,

    instanceDeposits: {},
    instanceQueryParams: {},

    previewDetail: {},

    options: {},

    inquiryTransactionId: '',
};

type DepositQueryParams = {
    limit: number;
    page: number;
    orderBy: string;
    sort: string;
    depositName: string;
};

type DepositsResult = {
    deposits: any
    queryParams: DepositQueryParams
}

type DepositActionType = {
    type:
        | DepositActionTypes.API_RESPONSE_SUCCESS
        | DepositActionTypes.API_RESPONSE_ERROR
        | DepositActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | DepositActionTypes.GET_DEPOSITS
        | DepositActionTypes.GET_DEPOSIT
        | DepositActionTypes.INSERT_DEPOSIT
        | DepositActionTypes.VERIFY_DEPOSIT
        | DepositActionTypes.UPDATE_DEPOSIT
        | DepositActionTypes.RESET
        | DepositActionTypes.MANUAL_DEPOSIT

        | DepositActionTypes.INSERT_DEPOSIT_ID

        | DepositActionTypes.OPEN_SEARCH
        | DepositActionTypes.CLOSE_SEARCH
        | DepositActionTypes.SEARCH_DEPOSIT
        | DepositActionTypes.CLEAR_SEARCH

        | DepositActionTypes.PREVIEW_DEPOSIT_DETAIL
        | DepositActionTypes.CLEAR_PREVIEW_DEPOSIT_DETAIL
        | DepositActionTypes.INQUIRY_PAYMENT
        | DepositActionTypes.EXPORT
        | DepositActionTypes.RESET_SEARCH
        | DepositActionTypes.GATEWAYS_STATUS
        | DepositActionTypes.TOTAL_DEPOSITS
        | DepositActionTypes.INSERT_OFFLINE_DEPOSIT
        | DepositActionTypes.GET_INSTANCE_DEPOSITS
        | DepositActionTypes.COMPLETE_GW_DEPOSIT
        | DepositActionTypes.GET_OPTIONS;

    payload: {
        actionType?: string;
        data?: DepositsResult | any;
        error?: string;
        validationErrors?: [];

        key?: any
        value?: any;

        transactionId: string
    };
};

type State = {
    deposits?: any;

    queryParams?: {};

    value?: boolean;
    tmpDeposits?: any;

    tmpQueryParams?: any;

    loading: {},
    error: {},
    validationErrors: {},
};

const Deposit = (state: State = INIT_STATE, action: DepositActionType) => {
    switch (action.type) {
        case DepositActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DepositActionTypes.GET_DEPOSITS: {
                    return {
                        ...state,
                        deposits: action.payload.data?.deposits,
                        queryParams: action.payload.data?.queryParams,
                        loading: {
                            deposits: false
                        },
                    };
                }
                case DepositActionTypes.GET_DEPOSIT: {
                    return {
                        ...state,
                        deposit: action.payload.data,
                        loading: {
                            deposit: false
                        },
                    };
                }
                case DepositActionTypes.GATEWAYS_STATUS: {
                    return {
                        ...state,
                        gateways: action.payload.data,
                        loading: {
                            gatewayStatus: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT: {
                    return {
                        ...state,
                        depositInsertedUrl: action.payload.data,
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.COMPLETE_GW_DEPOSIT: {
                    return {
                        ...state,
                        depositInsertedUrl: action.payload.data,
                        loading: {
                            complete: false
                        },
                    };
                }
                case DepositActionTypes.VERIFY_DEPOSIT: {
                    return {
                        ...state,
                        transactionVerified: action.payload.data,
                        loading: {
                            verify: false
                        },
                    };
                }
                case DepositActionTypes.UPDATE_DEPOSIT: {
                    return {
                        ...state,
                        depositUpdated: true,
                        loading: {
                            update: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT_ID: {
                    return {
                        ...state,
                        insertedDepositId: action.payload.data,
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.MANUAL_DEPOSIT: {
                    return {
                        ...state,
                        depositInserted: true,
                        loading: {
                            manualDeposit: false
                        },
                    };
                }
                case DepositActionTypes.TOTAL_DEPOSITS: {
                    return {
                        ...state,
                        totalDeposits: action.payload.data,
                        loading: {
                            totalDeposits: false
                        },
                    };
                }
                case DepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        // this condition used for prevent multiple search
                        tmpDeposits: isEmptyObject(state.tmpDeposits)? state.deposits: state.tmpDeposits,
                        tmpQueryParams: isEmptyObject(state.tmpQueryParams)? state.queryParams: state.tmpQueryParams,

                        deposits: action.payload.data?.deposits,
                        queryParams: action.payload.data?.queryParams,

                        searchMode: true,
                        searchModal: false,

                        loading: {
                            search: false,
                            export: false,
                        },
                    };
                }
                case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL: {
                    return {
                        ...state,
                        previewDetail: action.payload.data,
                        loading: {
                            previewDetail: false
                        },
                    };
                }
                case DepositActionTypes.INQUIRY_PAYMENT: {

                    return {
                        ...state,
                        deposits: {
                            ...state.deposits,
                            data: state.deposits.data.filter(function(item: any) {
                                return item.transaction_id !== action.payload.data.transactionId;
                            })
                        },
                        loading: {
                            inquiryPayment: false
                        },
                    };
                }
                case DepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        exportUrl: action.payload.data,

                        searchMode: false,
                        searchModal: false,

                        loading: {
                            search: false,
                            export: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_OFFLINE_DEPOSIT: {
                    return {
                        ...state,
                        insertedOfflineDepositId: action.payload.data,
                        loading: {
                            insert: false,
                        },
                    };
                }
                case DepositActionTypes.GET_INSTANCE_DEPOSITS: {
                    return {
                        ...state,
                        instanceDeposits: action.payload.data?.deposits,
                        instanceQueryParams: action.payload.data?.queryParams,

                        loading: {
                            instance: false,
                        },
                    };
                }
                case DepositActionTypes.GET_OPTIONS: {
                    return {
                        ...state,
                        options: action.payload.data,
                        loading: {
                            getOption: false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case DepositActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DepositActionTypes.GET_DEPOSITS:
                {
                    return {
                        ...state,
                        error: {
                            deposits: action.payload.error
                        },
                        loading: {
                            deposits: false
                        },
                    };
                }
                case DepositActionTypes.GET_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            deposit: action.payload.error
                        },
                        loading: {
                            deposit: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            insert: action.payload.error
                        },
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.VERIFY_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            verify: action.payload.error
                        },
                        loading: {
                            verify: false
                        },
                    };
                }
                case DepositActionTypes.UPDATE_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            update: action.payload.error
                        },
                        loading: {
                            update: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT_ID:
                {
                    return {
                        ...state,
                        error: {
                            insert: action.payload.error
                        },
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.MANUAL_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            manualDeposit: action.payload.error
                        },
                        loading: {
                            manualDeposit: false
                        },
                    };
                }
                case DepositActionTypes.INQUIRY_PAYMENT:
                {
                    return {
                        ...state,
                        error: {
                            inquiryPayment: action.payload.error
                        },
                        loading: {
                            inquiryPayment: false
                        },
                    };
                }
                case DepositActionTypes.GATEWAYS_STATUS:
                {
                    return {
                        ...state,
                        error: {
                            gatewayStatus: action.payload.error
                        },
                        loading: {
                            gatewayStatus: false
                        },
                    };
                }
                case DepositActionTypes.TOTAL_DEPOSITS:
                {
                    return {
                        ...state,
                        error: {
                            totalDeposits: action.payload.error
                        },
                        loading: {
                            totalDeposits: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_OFFLINE_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            insert: action.payload.error
                        },
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.COMPLETE_GW_DEPOSIT:
                {
                    return {
                        ...state,
                        error: {
                            complete: action.payload.error
                        },
                        loading: {
                            complete: false
                        },
                    };
                }
                case DepositActionTypes.GET_INSTANCE_DEPOSITS:
                {
                    return {
                        ...state,
                        error: {
                            instance: action.payload.error
                        },
                        loading: {
                            instance: false
                        },
                    };
                }
                case DepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        error: {
                            search: action.payload.error
                        },
                        loading: {
                            search: false
                        },
                    };
                }
                case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL: {
                    return {
                        ...state,
                        error: {
                            previewDetail: action.payload.error
                        },
                        loading: {
                            previewDetail: false
                        },
                    };
                }
                case DepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        error: {
                            export: action.payload.error
                        },
                        loading: {
                            export: false
                        },
                    };
                }
                case DepositActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        error: {
                            "getOption": action.payload.error
                        },
                        loading: {
                            "getOption": false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case DepositActionTypes.API_RESPONSE_VALIDATION_ERRORS:

            switch (action.payload.actionType) {
                case DepositActionTypes.GET_DEPOSITS: {
                    return {
                        ...state,
                        error: {
                            deposits: "Invalid input error"
                        },
                        loading: {
                            deposits: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            insert: action.payload.validationErrors,
                        },
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.VERIFY_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            verify: action.payload.validationErrors,
                        },
                        loading: {
                            verify: false
                        },
                    };
                }
                case DepositActionTypes.UPDATE_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            update: action.payload.validationErrors,
                        },
                        loading: {
                            update: false
                        },
                    };
                }
                case DepositActionTypes.MANUAL_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            manualDeposit: action.payload.validationErrors,
                        },
                        loading: {
                            manualDeposit: false
                        },
                    };
                }
                case DepositActionTypes.GET_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            deposit: action.payload.validationErrors,
                        },
                        loading: {
                            deposit: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_DEPOSIT_ID:
                {
                    return {
                        ...state,
                        validationErrors: {
                            insert: action.payload.validationErrors,
                        },
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL:
                {
                    return {
                        ...state,
                        validationErrors: {
                            previewDetail: action.payload.validationErrors,
                        },
                        loading: {
                            previewDetail: false
                        },
                    };
                }
                case DepositActionTypes.INQUIRY_PAYMENT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            inquiryPayment: action.payload.validationErrors,
                        },
                        loading: {
                            inquiryPayment: false
                        },
                    };
                }
                case DepositActionTypes.GATEWAYS_STATUS:
                {
                    return {
                        ...state,
                        validationErrors: {
                            gatewayStatus: action.payload.validationErrors,
                        },
                        loading: {
                            gatewayStatus: false
                        },
                    };
                }
                case DepositActionTypes.TOTAL_DEPOSITS:
                {
                    return {
                        ...state,
                        validationErrors: {
                            totalDeposits: action.payload.validationErrors,
                        },
                        loading: {
                            totalDeposits: false
                        },
                    };
                }
                case DepositActionTypes.INSERT_OFFLINE_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            insert: action.payload.validationErrors,
                        },
                        loading: {
                            insert: false
                        },
                    };
                }
                case DepositActionTypes.COMPLETE_GW_DEPOSIT:
                {
                    return {
                        ...state,
                        validationErrors: {
                            complete: action.payload.validationErrors,
                        },
                        loading: {
                            complete: false
                        },
                    };
                }
                case DepositActionTypes.GET_INSTANCE_DEPOSITS:
                {
                    return {
                        ...state,
                        validationErrors: {
                            instance: action.payload.validationErrors,
                        },
                        loading: {
                            instance: false
                        },
                    };
                }
                case DepositActionTypes.SEARCH_DEPOSIT: {
                    return {
                        ...state,
                        validationErrors: {
                            search: action.payload.validationErrors,
                        },
                        loading: {
                            search: false
                        },
                    };
                }
                case DepositActionTypes.EXPORT: {
                    return {
                        ...state,
                        validationErrors: {
                            export: action.payload.validationErrors,
                        },
                        loading: {
                            export: false
                        },
                    };
                }
                case DepositActionTypes.GET_OPTIONS:
                {
                    return {
                        ...state,
                        validationErrors: {
                            getOption: action.payload.validationErrors
                        },
                        loading: {
                            getOption: false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case DepositActionTypes.GET_DEPOSITS:
            return {
                ...state,
                // deposits: {},
                loading: {
                    deposits: true
                },
                error: {
                    deposits: ""
                },
            };

        case DepositActionTypes.GET_INSTANCE_DEPOSITS:
            return {
                ...state,
                loading: {
                    instance: true
                },
                error: {
                    instance: ""
                },
            };

        case DepositActionTypes.GET_DEPOSIT:
            return {
                ...state,
                deposit: {},
                loading: {
                    deposit: true
                },
                error: {
                    deposit: ""
                },
            };

        case DepositActionTypes.GATEWAYS_STATUS:
            return {
                ...state,
                gateways: [],
                loading: {
                    gatewayStatus: true
                },
                error: {
                    gatewayStatus: ""
                },
            };

        case DepositActionTypes.INSERT_DEPOSIT:
            return {
                ...state,
                gateways: [],
                loading: {
                    insert: true
                },
                error: {
                    insert: ""
                },
            };
        case DepositActionTypes.COMPLETE_GW_DEPOSIT:
            return {
                ...state,
                depositInsertedUrl: "",
                loading: {
                    complete: true
                },
                error: {
                    complete: ""
                },
            };

        case DepositActionTypes.VERIFY_DEPOSIT:
            return {
                ...state,
                transactionVerified: false,
                loading: {
                    verify: true
                },
                error: {
                    verify: ""
                },
            };

        case DepositActionTypes.MANUAL_DEPOSIT:
            return {
                ...state,
                depositInserted: false,
                loading: {
                    manualDeposit: true
                },
                error: {
                    manualDeposit: ""
                },
            };

        case DepositActionTypes.UPDATE_DEPOSIT:
            return {
                ...state,
                depositUpdated: false,
                loading: {
                    update: true
                },
                error: {
                    update: ""
                },
            };

        case DepositActionTypes.INSERT_DEPOSIT_ID:
            return {
                ...state,
                insertedDepositId: "",
                loading: {
                    insert: true
                },
                error: {
                    insert: ""
                },
            };

        case DepositActionTypes.TOTAL_DEPOSITS:
            return {
                ...state,
                loading: {
                    totalDeposits: true
                },
                error: {
                    totalDeposits: ""
                },
            };

        case DepositActionTypes.INSERT_OFFLINE_DEPOSIT:
            return {
                ...state,
                insertedOfflineDepositId: null,
                loading: {
                    insert: true
                },
                error: {
                    insert: ""
                },
            };

        case DepositActionTypes.RESET:
            return {
                ...state,
                deposits: {},
                queryParams: {},

                deposit: {},

                error: {},
                loading: {},
                validationErrors: {},

                depositInsertedUrl: "",
                depositInserted: false,
                transactionVerified: false,

                depositUpdated: false,

                searchModal: false,
                searchMode: false,

                tmpDeposits: {},
                tmpQueryParams: {},

                previewDetail: {},

                exportUrl: "",

                gateways: [],

                totalDeposits: {},

                instanceDeposits: {},
                instanceQueryParams: {},

                options: {},

                inquiryTransactionId: '',
            };


        // search actions
        case DepositActionTypes.OPEN_SEARCH:
            return {
                ...state,
                searchModal: true,
            };
        case DepositActionTypes.CLOSE_SEARCH:
            return {
                ...state,
                searchModal: false,

                loading: {
                    search: true
                },
                error: {
                    search: ""
                },
                validationErrors: {
                    search: false
                },
            };
        case DepositActionTypes.SEARCH_DEPOSIT:
            return {
                ...state,
                loading: {
                    search: true
                },
                error: {
                    search: ""
                },
                validationErrors: {
                    search: false
                },
            };

        case DepositActionTypes.CLEAR_SEARCH:
            return {
                ...state,
                deposits: state.tmpDeposits,
                queryParams: state.tmpQueryParams,
                tmpDeposits: {},
                tmpQueryParams: {},
                searchMode: false,
            };

        case DepositActionTypes.PREVIEW_DEPOSIT_DETAIL:
            return {
                ...state,
                previewDetail: {},
                loading: {
                    previewDetail: true
                },
                error: {
                    previewDetail: false
                },
            };

        case DepositActionTypes.CLEAR_PREVIEW_DEPOSIT_DETAIL:
            return {
                ...state,
                previewDetail: {},
                loading: {
                    previewDetail: false
                },
                error: {
                    previewDetail: false
                },
            };

        case DepositActionTypes.INQUIRY_PAYMENT:
            return {
                ...state,
                inquiryTransactionId: action.payload.transactionId,
                loading: {
                    inquiryPayment: true,
                },
                error: {
                    inquiryPayment: false
                },
            };
        case DepositActionTypes.EXPORT:

            return {
                ...state,
                exportUrl: "",
                loading: {
                    export: true
                },
                error: {
                    export: ""
                },
                validationErrors: {
                    search: false
                },
            };

        case DepositActionTypes.RESET_SEARCH:

            return {
                ...state,
                searchModal: false,
                loading: {
                    search: false,
                    export: false,
                },
                error: {
                    search: "",
                    export: ""
                },
                validationErrors: {
                    search: false,
                    export: false
                },

                exportUrl: "",
            }

        case DepositActionTypes.GET_OPTIONS:
            return {
                ...state,
                options: {},
                loading: {
                    getOption: true
                },
                error: {
                    getOption: "",
                },
            };

        default:
            return { ...state };
    }
};

export default Deposit;

import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { graphql } from 'helpers';
import {DepositActionTypes, DepositErrors} from './constants';
import {
    checkResponseError,
    checkServerError,
    pluckResponse,
} from "../../helpers/functions";
import {depositActions} from "./actions";

type DepositData = {
    payload: {
        id: string;
        name: string;
        permissions: string[];

        queryParams: {
            limit: string;
            page: string;
            orderBy: string;
            sort: string;

            depositName?: string;
        };

        data: any;
        trackId: string;
        transactionId: string;
    };
    type: string;
};

function* getDeposits({ payload: { queryParams } }: DepositData): SagaIterator {

    try {
        const param = {
            query:`query PendingDeposits($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String, $wallet: String) {
                pendingDeposits(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId, wallet: $wallet) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        confirmed
                        meta {
                            gateway_slug
                            order_id
                            description
                        }
                        uuid
                        amountFloat
                        balanceFloat
                        commission_float
                        bank_name
                        iban
                        card_number
                        bank_account_number
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rDeposits = response.data;

        checkServerError(rDeposits);

        const deposits = pluckResponse(rDeposits, "pendingDeposits");

        const result = {
            deposits,
            queryParams
        }

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.GET_DEPOSITS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.GET_DEPOSITS, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.GET_DEPOSITS, error));
        }
    }
}

function* getDeposit({ payload: { id } }: DepositData): SagaIterator {

    try {
        const param = {
            query:`query Deposit($id: String!) {
                deposit(id: $id) {
                    wallet {
                        uuid
                        name
                        holder {
                            id
                            email
                            username
                            display_name
                        }
                        meta {
                            sign
                            currency
                        }
                    }
                    type
                    confirmed
                    meta {
                        description
                    }
                    method
                    amountFloat
                    fee_float
                    discount_float
                    bank_charge_fee_float
                    commission_float
                    final_amount_float
                    
                    bank_name
                    iban
                    card_number
                    bank_account_id
                    bank_account_number
                    track_id
                    date
                    name
                    uuid
                    transaction_id
                    thread_id
                    status
                    created_at
                }
            }`,

            variables: {
                id: id
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rD = response.data;

        checkServerError(rD);

        const deposit = pluckResponse(rD, "deposit");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.GET_DEPOSIT, deposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.GET_DEPOSIT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.GET_DEPOSIT, error));
        }
    }
}

function* insertDeposit({ payload: {data} }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation CreateDeposit(
                $bankCard: String!,
                $amount: Int!,
                $method: String,
            ) {
                createDeposit(
                    bankCard: $bankCard,
                    amount: $amount,
                    method: $method
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const createDeposit = pluckResponse(rCD, "createDeposit");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.INSERT_DEPOSIT, createDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.INSERT_DEPOSIT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.INSERT_DEPOSIT, error));
        }
    }
}

function* completeGwDeposit({ payload: {transactionId} }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation CompleteGwDeposit($transactionId: String!) {
                completeGwDeposit(transactionId: $transactionId)
            }`,

            variables: {
                transactionId: transactionId
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const createDeposit = pluckResponse(rCD, "completeGwDeposit");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.COMPLETE_GW_DEPOSIT, createDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.COMPLETE_GW_DEPOSIT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.COMPLETE_GW_DEPOSIT, error));
        }
    }
}

function* verifyDeposit({ payload: {trackId} }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation VerifyDeposit(
                $trackId: String!
            ) {
                verifyDeposit(
                    trackId: $trackId
                )
            }`,

            variables: { trackId }
        };

        const response: any = yield call(graphql, param, '');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rVD = response.data;

        checkServerError(rVD);

        const verifyDeposit = pluckResponse(rVD, "verifyDeposit");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.VERIFY_DEPOSIT, verifyDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.VERIFY_DEPOSIT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.VERIFY_DEPOSIT, error));
        }
    }
}

function* updateDeposit({ payload: {
    id,
    name,
    permissions,
} }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation UpdateDeposit(
                $id: Int!,
                $name: String!,
                $permissions: [String!]
            ) {
                updateDeposit(
                    id: $id,
                    name: $name,
                    permissions: $permissions
                ) {
                    name
                }
            }`,
            variables: {
                "id": id,
                "name": name,
                "permissions": permissions
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rUpdateDeposit = response.data;

        checkServerError(rUpdateDeposit);

        const updateDeposit = pluckResponse(rUpdateDeposit, "updateDeposit");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.UPDATE_DEPOSIT, updateDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.UPDATE_DEPOSIT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.UPDATE_DEPOSIT, error));
        }
    }
}

function* insertDepositId({ payload: {data} }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation CreateUniqueIdDeposit(
                $iban: String!,
                $amount: String!
            ) {
                createUniqueIdDeposit(
                    iban: $iban,
                    amount: $amount
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rCD = response.data;

        checkServerError(rCD);

        const createUniqueIdDeposit = pluckResponse(rCD, "createUniqueIdDeposit");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.INSERT_DEPOSIT_ID, createUniqueIdDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.INSERT_DEPOSIT_ID, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.INSERT_DEPOSIT_ID, error));
        }
    }
}

function* searchDeposit({ payload: { queryParams } }: DepositData): SagaIterator {
    try {
        const param = {
            query:`query PendingDeposits(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                pendingDeposits(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                ) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        amountFloat
                        confirmed
                        meta {                            
                            description
                        }
                        method
                        fee
                        discount_float
                        bank_charge_fee_float
                        commission_float
                        final_amount_float
                        
                        bank_name
                        iban
                        card_number
                        bank_account_id
                        bank_account_number
                        track_id
                        date
                        name
                        uuid
                        
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rDeposits = response.data;

        checkServerError(rDeposits);

        const deposits = pluckResponse(rDeposits, "pendingDeposits");

        const result = {
            deposits,
            queryParams
        }

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.SEARCH_DEPOSIT, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.SEARCH_DEPOSIT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.SEARCH_DEPOSIT, error));
        }
    }
}

function* manualDeposit({ payload: {data} }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation ManualDeposit(
                $accountId: String!,
                $wallet: String!,
                $amount: String!,
                $fee: String!,
                $method: String!,
                $description: String
                $bankName: String
                $cardNumber: String
                $iban: String
                $bankAccountNumber: String
            ) {
                manualDeposit(
                    accountId: $accountId
                    wallet: $wallet
                    amount: $amount
                    fee: $fee
                    method: $method
                    description: $description
                    bankName: $bankName
                    cardNumber: $cardNumber
                    iban: $iban
                    bankAccountNumber: $bankAccountNumber
                )
            }`,

            variables: { ...data }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rMD = response.data;

        checkServerError(rMD);

        const manualDeposit = pluckResponse(rMD, "manualDeposit");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.MANUAL_DEPOSIT, manualDeposit));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.MANUAL_DEPOSIT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.MANUAL_DEPOSIT, error));
        }
    }
}

function* previewDepositDetail({ payload: { data } }: DepositData): SagaIterator {
    try {
        const param = {
            query:`query PreviewDepositDetail($wallet: String!, $amount: String!, $fee: String!) {
                previewDepositDetail(wallet: $wallet, amount: $amount, fee: $fee) {
                    subTotal
                    fee
                    total
                }
            }`,

            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rDD = response.data;

        checkServerError(rDD);

        const previewDepositDetail = pluckResponse(rDD, "previewDepositDetail");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.PREVIEW_DEPOSIT_DETAIL, previewDepositDetail));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.PREVIEW_DEPOSIT_DETAIL, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.PREVIEW_DEPOSIT_DETAIL, error));
        }
    }
}

function* inquiryPayment({ payload: { transactionId } }: DepositData): SagaIterator {
    try {
        const param = {
            query:`mutation InquiryGatewayPayment($transactionId: String!) {
                inquiryGatewayPayment(transactionId: $transactionId)
            }`,

            variables: {
                transactionId: transactionId
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rDD = response.data;

        checkServerError(rDD);

        const inquiryPayment = pluckResponse(rDD, "inquiryGatewayPayment");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.INQUIRY_PAYMENT, {
            inquiryPayment,
            transactionId
        }));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.INQUIRY_PAYMENT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.INQUIRY_PAYMENT, error));
        }
    }
}

function* exportTransactions({ payload: { queryParams } }: DepositData): SagaIterator {
    try {
        const param = {
            query:`query ExportPendingDeposits(
                $page: Int!, 
                $limit: Int, 
                $orderBy: String!, 
                $sort: String!, 
                $wallet: String, 
                $minAmount: String,
                $maxAmount: String,
                $createdFrom: String,
                $createdTo: String,
                $transactionId: String,
                $threadId: String,
                $accountId: String,
                $transactionNames: [String],
                $transactionType: String
            ) {
                exportPendingDeposits(
                    page: $page, 
                    limit: $limit, 
                    orderBy: $orderBy, 
                    sort: $sort, 
                    wallet: $wallet,
                    minAmount: $minAmount,
                    maxAmount: $maxAmount,
                    createdFrom: $createdFrom,
                    createdTo: $createdTo,
                    transactionId: $transactionId,
                    threadId: $threadId,
                    accountId: $accountId,
                    transactionNames: $transactionNames,
                    transactionType: $transactionType
                )
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rTransactions = response.data;

        checkServerError(rTransactions);

        const transactions = pluckResponse(rTransactions, "exportPendingDeposits");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.EXPORT, transactions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.EXPORT, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.EXPORT, error));
        }
    }
}

function* gatewayStatus(): SagaIterator {

    try {
        const param = {
            query:`query GatewaysStatus {
                gatewaysStatus {
                    slug
                    name
                    status
                }
            }`
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rGS = response.data;

        checkServerError(rGS);

        const gatewaysStatus = pluckResponse(rGS, "gatewaysStatus");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.GATEWAYS_STATUS, gatewaysStatus));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.GATEWAYS_STATUS, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.GATEWAYS_STATUS, error));
        }
    }
}

function* totalDeposits({ payload: { data } }: DepositData): SagaIterator {

    try {
        const param = {
            query:`query TotalDeposits (
                $name: [String!]
                $status: [String]
                $dateFrom: String
                $dateTo: String
                $today: String
            ) {
                totalDeposits (
                    name: $name
                    status: $status
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    today: $today
                ) {
                    total
                    count
                }
            }`,
            variables: {
                ...data
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rGS = response.data;

        checkServerError(rGS);

        const totalGatewayDeposits = pluckResponse(rGS, "totalDeposits");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.TOTAL_DEPOSITS, totalGatewayDeposits));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.TOTAL_DEPOSITS, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.TOTAL_DEPOSITS, error));
        }
    }
}

function* getInstanceDeposits({ payload: { queryParams } }: DepositData): SagaIterator {

    try {
        const param = {
            query:`query PendingDeposits($page: Int!, $limit: Int, $orderBy: String!, $sort: String!, $accountId: String, $wallet: String) {
                pendingDeposits(page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, accountId: $accountId, wallet: $wallet) {
                    data {
                        wallet {
                            uuid
                            name
                            holder {
                                id
                                email
                                username
                                display_name
                            }
                            meta {
                                sign
                                currency
                            }
                        }
                        type
                        confirmed
                        meta {
                            gateway_slug
                            order_id
                            description
                        }
                        uuid
                        amountFloat
                        balanceFloat
                        commission_float
                        bank_name
                        iban
                        card_number
                        bank_account_number
                        name
                        transaction_id
                        thread_id
                        status
                        created_at
                    }
                    total
                    per_page
                    from
                    to
                    current_page
                    last_page
                    has_more_pages
                }
            }`,

            variables: {
                ...queryParams
            }
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const rDeposits = response.data;

        checkServerError(rDeposits);

        const deposits = pluckResponse(rDeposits, "pendingDeposits");

        const result = {
            deposits,
            queryParams
        }

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.GET_INSTANCE_DEPOSITS, result));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.GET_INSTANCE_DEPOSITS, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.GET_INSTANCE_DEPOSITS, error));
        }
    }
}

function* getOptions({ payload: { data } }: DepositData): SagaIterator {

    try {

        const param = {
            query:`query GatewayDepositOptions($options: [String!]) {
                gatewayDepositOptions(options: $options) {
                    gateway_deposit_status
                    gateways {
                        label
                        slug
                        status
                        url
                        description
                        min_amount
                        max_amount
                        total_amount
                    }
                }
            }`,

            variables: {
                options: data
            },

            operationName: "GatewayDepositOptions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, DepositErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const gatewayDepositOptions = pluckResponse(responseCR, "gatewayDepositOptions");

        yield put(depositActions.apiResponseSuccess(DepositActionTypes.GET_OPTIONS, gatewayDepositOptions));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(depositActions.apiResponseError(DepositActionTypes.GET_OPTIONS, error));
        } else {
            yield put(depositActions.apiResponseValidationErrors(DepositActionTypes.GET_OPTIONS, error));
        }
    }
}

export function* watchGetDeposits() {
    yield takeEvery(DepositActionTypes.GET_DEPOSITS, getDeposits);
}

export function* watchGetDeposit() {
    yield takeEvery(DepositActionTypes.GET_DEPOSIT, getDeposit);
}

export function* watchInsertDeposit() {
    yield takeEvery(DepositActionTypes.INSERT_DEPOSIT, insertDeposit);
}

export function* watchVerifyDeposit() {
    yield takeEvery(DepositActionTypes.VERIFY_DEPOSIT, verifyDeposit);
}

export function* watchInsertDepositId() {
    yield takeEvery(DepositActionTypes.INSERT_DEPOSIT_ID, insertDepositId);
}

export function* watchUpdateDeposit() {
    yield takeEvery(DepositActionTypes.UPDATE_DEPOSIT, updateDeposit);
}

export function* watchSearchDeposit() {
    yield takeEvery(DepositActionTypes.SEARCH_DEPOSIT, searchDeposit);
}

export function* watchManualDeposit() {
    yield takeEvery(DepositActionTypes.MANUAL_DEPOSIT, manualDeposit);
}

export function* watchPreviewDepositDetail() {
    yield takeEvery(DepositActionTypes.PREVIEW_DEPOSIT_DETAIL, previewDepositDetail);
}

export function* watchInquiryPayment() {
    yield takeEvery(DepositActionTypes.INQUIRY_PAYMENT, inquiryPayment);
}

export function* watchExportTransactions() {
    yield takeEvery(DepositActionTypes.EXPORT, exportTransactions);
}

export function* watchGatewayStatus() {
    yield takeEvery(DepositActionTypes.GATEWAYS_STATUS, gatewayStatus);
}

export function* watchTotalDeposits() {
    yield takeEvery(DepositActionTypes.TOTAL_DEPOSITS, totalDeposits);
}

export function* watchGetInstanceDeposits() {
    yield takeEvery(DepositActionTypes.GET_INSTANCE_DEPOSITS, getInstanceDeposits);
}

function* watchGetOptions() {
    yield takeLatest(DepositActionTypes.GET_OPTIONS, getOptions);
}

export function* watchCompleteGwDeposit() {
    yield takeEvery(DepositActionTypes.COMPLETE_GW_DEPOSIT, completeGwDeposit);
}

function* depositsSaga() {
    yield all([
        fork(watchGetDeposits),
        fork(watchGetDeposit),
        fork(watchInsertDeposit),
        fork(watchVerifyDeposit),
        fork(watchUpdateDeposit),
        fork(watchInsertDepositId),
        fork(watchSearchDeposit),
        fork(watchManualDeposit),
        fork(watchPreviewDepositDetail),
        fork(watchInquiryPayment),
        fork(watchExportTransactions),
        fork(watchGatewayStatus),
        fork(watchTotalDeposits),
        fork(watchGetInstanceDeposits),
        fork(watchCompleteGwDeposit),
        fork(watchGetOptions),
    ]);
}

export default depositsSaga;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GatewayDepositSettingActionTypes, GatewayDepositSettingErrors } from './constants';
import {gatewayDepositSettingActions} from "./actions";
import {graphql} from "../../helpers";
import { SagaIterator } from "@redux-saga/core";
import { checkResponseError, checkServerError, pluckResponse } from "../../helpers/functions";

type GatewayDepositSettingData = {
    payload: {
        id: string;

        limit: string;
        page: string;

        data: any,
        trackId: string
    };
    type: string;
};

function* updateSetting({ payload: { data, trackId } }: any): SagaIterator {
    try {
        const { daily_total_user_deposits, gateway_deposit_status, gateways } = data;

        const param = {
            query: `mutation UpdateGatewayDepositSetting(
                $gateway_deposit_status: String!,
                $daily_total_user_deposits: String!,
                $gateways: [GatewayInput!]
            ) {
                updateGatewayDepositSetting(
                    gateway_deposit_status: $gateway_deposit_status,
                    daily_total_user_deposits: $daily_total_user_deposits,
                    gateways: $gateways
                )
            }`,
            variables: {
                gateway_deposit_status,
                daily_total_user_deposits,
                gateways,
            },
            operationName: "UpdateGatewayDepositSetting"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, GatewayDepositSettingErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        pluckResponse(responseCR, "updateGatewayDepositSetting");

        yield put(gatewayDepositSettingActions.apiResponseSuccess(
            GatewayDepositSettingActionTypes.UPDATE_SETTING,
            { result: true, trackId }
        ));
    } catch (error: any) {
        if (typeof error === "string") {
            yield put(gatewayDepositSettingActions.apiResponseError(
                GatewayDepositSettingActionTypes.UPDATE_SETTING,
                error,
                trackId
            ));
        } else {
            yield put(gatewayDepositSettingActions.apiResponseValidationErrors(
                GatewayDepositSettingActionTypes.UPDATE_SETTING,
                error,
                trackId
            ));
        }
    }
}


function* getOptions({ payload: { data } }: GatewayDepositSettingData): SagaIterator {

    try {

        const param = {
            query:`query GatewayDepositOptions($options: [String!]) {
                gatewayDepositOptions(options: $options) {
                    gateway_deposit_status
                    daily_total_user_deposits
                    gateways {
                        label
                        slug
                        status
                        url
                        description
                        min_amount
                        max_amount
                        total_amount
                    }
                }
            }`,

            variables: {
                options: data
            },

            operationName: "GatewayDepositOptions"
        };

        const response: any = yield call(graphql, param, 'auth');

        checkResponseError(response, GatewayDepositSettingErrors.RESPONSE_200);

        const responseCR = response.data;

        checkServerError(responseCR);

        const premiumSubscriptionSetting = pluckResponse(responseCR, "gatewayDepositOptions");

        yield put(gatewayDepositSettingActions.apiResponseSuccess(GatewayDepositSettingActionTypes.GET_OPTIONS, premiumSubscriptionSetting));

    } catch (error: any) {
        if(typeof error === "string") {
            yield put(gatewayDepositSettingActions.apiResponseError(GatewayDepositSettingActionTypes.GET_OPTIONS, error));
        } else {
            yield put(gatewayDepositSettingActions.apiResponseValidationErrors(GatewayDepositSettingActionTypes.GET_OPTIONS, error));
        }
    }
}

export function* watchUpdateSetting() {
    yield takeEvery(GatewayDepositSettingActionTypes.UPDATE_SETTING, updateSetting);
}

export function* watchGetOptions() {
    yield takeEvery(GatewayDepositSettingActionTypes.GET_OPTIONS, getOptions);
}

function* gatewayDepositSettingSaga() {
    yield all([
        fork(watchUpdateSetting),
        fork(watchGetOptions),
    ]);
}

export default gatewayDepositSettingSaga;

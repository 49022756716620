import React, {Suspense, useCallback} from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useSelector } from "react-redux";
import { memoize } from "proxy-memoize";

// lazy load all the views
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgotPassword = React.lazy(() => import('pages/account/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/account/ResetPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));
const GoogleCallback = React.lazy(() => import('pages/account/GoogleCallback'));

const Users = React.lazy(() => import('pages/user/Users'));
const Logs = React.lazy(() => import('../pages/log/Logs'));
const NewUser = React.lazy(() => import('pages/user/NewUser'));
const ViewUser = React.lazy(() => import('pages/user/ViewUser'));
const EditUser = React.lazy(() => import('pages/user/EditUser'));
const EditMerchantSettings = React.lazy(() => import('pages/user/EditMerchantSettings'));

const Permissions = React.lazy(() => import('pages/role/Permissions'));
const Roles = React.lazy(() => import('pages/role/Roles'));
const NewRole = React.lazy(() => import('pages/role/NewRole'));
const EditRole = React.lazy(() => import('pages/role/EditRole'));

const Dashboard = React.lazy(() => import('pages/dashboard'));

const VerifyEmail = React.lazy(() => import('pages/myAccount/VerifyEmail'));

// doneeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
const VerificationPersonalDetails = React.lazy(() => import('../pages/verification/PersonalDetails'));
const ManageVerifications = React.lazy(() => import('../pages/verification/PersonalDetails/ManageVerifications'));
const VerificationPersonalDetail = React.lazy(() => import('../pages/verification/PersonalDetails/EditPersonalDetail'));

// doneeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
const VerificationMobileNumbers = React.lazy(() => import('../pages/verification/MobileNumbers'));
const MobileVerification = React.lazy(() => import('../pages/verification/MobileNumbers/MobileVerification'));
// const SendOtp = React.lazy(() => import('../pages/verification/MobileNumbers/SendOtp'));
// const VerifyOtp = React.lazy(() => import('../pages/verification/MobileNumbers/VerifyOtp'));

// done
const VerificationBankCards = React.lazy(() => import('../pages/verification/BankCards'));
const VRCreateBankCard = React.lazy(() => import('../pages/verification/BankCards/CreateBankCard'));
const EditBankCard = React.lazy(() => import('../pages/verification/BankCards/EditBankCard'));
const BankCards = React.lazy(() => import('../pages/BankCards'));
const CreateBankCard = React.lazy(() => import('../pages/BankCards/CreateBankCard'));

// done
const TermsAndConditions = React.lazy(() => import('../pages/verification/TermsAndConditions'));
const CreateTermAndCondition = React.lazy(() => import('../pages/verification/TermsAndConditions/CreateTermAndCondition'));
const UploadTermAndCondition = React.lazy(() => import('../pages/verification/TermsAndConditions/Upload'));
const CheckTermAndCondition = React.lazy(() => import('../pages/verification/TermsAndConditions/CheckTermsAndConditions'));

const MyAccount = React.lazy(() => import('../pages/myAccount/myAccount'));
const EditUserSetting = React.lazy(() => import('../pages/myAccount/editUser/EditUser'));
const EditPersonalDetail = React.lazy(() => import('../pages/myAccount/personalDetail/EditPersonalDetail'));
const UploadProfileImage = React.lazy(() => import('../pages/myAccount/profileImage/UploadImage'));
const ChangePassword = React.lazy(() => import('../pages/myAccount/password/ChangePassword'));

// deposits
// const Deposits = React.lazy(() => import('../pages/deposit/Deposits'));
const CreateDeposit = React.lazy(() => import('../pages/deposit/NewDeposit'));
const Redirecting = React.lazy(() => import('../pages/deposit/NewDeposit/bankGateway/Redirecting'));
const CreateManualDeposit = React.lazy(() => import('../pages/deposit/ManualDeposit'));
const VerifyDeposit = React.lazy(() => import('../pages/deposit/VerifyDeposit'));
const DepositInvoice = React.lazy(() => import('../pages/deposit/Invoice'));

// transfers
const Transfers = React.lazy(() => import('../pages/transfer/Transfers'));
const TransferTransactions = React.lazy(() => import('../pages/transfer/Transactions'));
const CreateTransfer = React.lazy(() => import('../pages/transfer/NewTransfer'));
const TransferInvoice = React.lazy(() => import('../pages/transfer/Invoice'));

// exchanges
const Exchanges = React.lazy(() => import('../pages/exchange/Exchanges'));
const ExchangeTransactions = React.lazy(() => import('../pages/exchange/Transactions'));
const CreateExchange = React.lazy(() => import('../pages/exchange/NewExchange'));
const ExchangeInvoice = React.lazy(() => import('../pages/exchange/Invoice'));

// withdraw
const Withdraws = React.lazy(() => import('../pages/withdraw/WithdrawRequests'));
const PendingDeposits = React.lazy(() => import('../pages/deposit/pendingDeposits/PendingDeposits'));
const OfflineDeposits = React.lazy(() => import('../pages/deposit/offlineDeposits/OfflineDeposits'));
const ApproveOfflineDeposit = React.lazy(() => import('../pages/deposit/offlineDeposits/Approve'));
const CreateWithdraw = React.lazy(() => import('../pages/withdraw/NewWithdraw'));
const CreateManualWithdraw = React.lazy(() => import('../pages/withdraw/ManualWithdraw'));
const ApproveIRRWithdraw = React.lazy(() => import('../pages/withdraw/ApproveIRRWithdraw'));
const WithdrawInvoice = React.lazy(() => import('../pages/withdraw/Invoice'));

const ExportRequests = React.lazy(() => import('../pages/exportRequests'));

// iban
const IBANs = React.lazy(() => import('../pages/IBANs'));
const CreateIBAN = React.lazy(() => import('../pages/IBANs/CreateIBAN'));

// token
const Tokens = React.lazy(() => import('../pages/Token'));
const CreateToken = React.lazy(() => import('../pages/Token/CreateToken'));
const IPs = React.lazy(() => import('../pages/IP'));
const CreateIp = React.lazy(() => import('../pages/IP/CreateIP'));
const Resources = React.lazy(() => import('../pages/Resources'));

const AccessDenied = React.lazy(() => import('pages/errors/AccessDenied'));
const SessionExpired = React.lazy(() => import('pages/errors/SessionExpired'));

const Enable2Fa = React.lazy(() => import('../pages/google2fa/Enable2Fa'));
const Disable2Fa = React.lazy(() => import('../pages/google2fa/Disable2Fa'));


// international payments
const Payments = React.lazy(() => import('../pages/payment/Payments'));
const PaymentInvoice = React.lazy(() => import('../pages/payment/Invoice'));
const PaymentTransactions = React.lazy(() => import('../pages/payment/Transactions'));


const Transactions = React.lazy(() => import('../pages/transactions/Transactions'));

const Wallets = React.lazy(() => import('../pages/wallets/Wallets'));
const DepositsWithdraws = React.lazy(() => import('../pages/depositWithdraw/DepositsWithdraws'));

// orders
const Orders = React.lazy(() => import('../pages/Orders'));
const NewOrder = React.lazy(() => import('../pages/Orders/NewOrder'));
const CashIncomeOrder = React.lazy(() => import('../pages/Orders/Show/CashIncome/Show'));
const IntlPaymentOrder = React.lazy(() => import('../pages/Orders/Show/IntlPayment/Show'));
const EduExpenseOrder = React.lazy(() => import('../pages/Orders/Show/EduExpensePayment/Show'));
const PremiumSubscriptionOrder = React.lazy(() => import('../pages/Orders/Show/PremiumSubscription/Show'));
const IntlMoneyTransferOrder = React.lazy(() => import('../pages/Orders/Show/IntlMoneyTransfer/Show'));
const OrderDeposit = React.lazy(() => import('../pages/Orders/OrderPayment'));
const CreateOrderBill = React.lazy(() => import('../pages/Orders/OrderBill'));
const OrderTransactions = React.lazy(() => import('../pages/Orders/transactions/Transactions'));
const OrderBills = React.lazy(() => import('../pages/Orders/Bills/Bills'));

const Bill = React.lazy(() => import('../pages/bills/Show'));

const CreateOrderNote = React.lazy(() => import('../pages/Orders/OrderNote'));
const EditCashIncomeOrder = React.lazy(() => import('../pages/Orders/EditOrder/cashIncome/EditOrderStep1'));
const EditIntlPaymentOrder = React.lazy(() => import('../pages/Orders/EditOrder/intlPayment/EditOrderStep1'));
const EditEduExpensePaymentOrder = React.lazy(() => import('../pages/Orders/EditOrder/eduExpensePayment/EditOrderStep1'));
const EditPremiumSubscriptionOrder = React.lazy(() => import('../pages/Orders/EditOrder/premiumSubscription/EditOrderStep1'));
const EditIntlMoneyTransferOrder = React.lazy(() => import('../pages/Orders/EditOrder/intlMoneyTransfer/EditOrderStep1'));


const Products = React.lazy(() => import('../pages/product/Products'));
const EditProducts = React.lazy(() => import('../pages/product/EditProduct'));
const CreateProduct = React.lazy(() => import('../pages/product/CreateProduct'));

const GiftCards = React.lazy(() => import('../pages/giftcard'));
const BuyGiftCard = React.lazy(() => import('../pages/giftcard/Buy'));
const Rates = React.lazy(() => import('../pages/rates/Rates'));
const ConversionRates = React.lazy(() => import('../pages/ConversionRates'));
const CreateConversionRate = React.lazy(() => import('../pages/ConversionRates/CreateConversionRate'));
const EditConversionRate = React.lazy(() => import('../pages/ConversionRates/EditConversionRate'));

const Setting = React.lazy(() => import('../pages/Setting'));
const Providers = React.lazy(() => import('../pages/Setting/Providers/Providers'));
const EditProvider = React.lazy(() => import('../pages/Setting/Providers/EditProvider'));
const SettingOrders = React.lazy(() => import('../pages/Setting/Orders'));
const SettingPremiumAccount = React.lazy(() => import('../pages/Setting/PremiumSubscription'));
const OfflineDepositsSetting = React.lazy(() => import('../pages/Setting/OfflineDeposit'));
const GatewayDepositsSetting = React.lazy(() => import('../pages/Setting/GatewayDeposit'));


// auth
// const Login = React.lazy(() => import('pages1/account/Login'));
// const Logout = React.lazy(() => import('pages1/account/Logout'));
// const Register = React.lazy(() => import('pages1/account/Register'));
// const Confirm = React.lazy(() => import('pages1/account/Confirm'));
// const ForgotPassword = React.lazy(() => import('pages1/account/ForgotPassword'));
// const LockScreen = React.lazy(() => import('pages1/account/LockScreen'));



// dashboard
const AnalyticsDashboard = React.lazy(() => import('pages1/dashboard/Analytics'));
const EcommerceDashboard = React.lazy(() => import('pages1/dashboard/Ecommerce'));
const ProjectDashboard = React.lazy(() => import('pages1/dashboard/Project'));
const EWalletDashboard = React.lazy(() => import('pages1/dashboard/E-Wallet'));

// apps
const ProjectList = React.lazy(() => import('pages1/apps/Projects/List'));
const ProjectDetail = React.lazy(() => import('pages1/apps/Projects/Detail/'));
const ProjectGannt = React.lazy(() => import('pages1/apps/Projects/Gantt'));
const ProjectForm = React.lazy(() => import('pages1/apps/Projects/ProjectForm'));

// - chat
const ChatApp = React.lazy(() => import('pages1/apps/Chat/'));

// -crm
const CRMDashboard = React.lazy(() => import('pages1/apps/CRM/Dashboard'));
const CRMProjects = React.lazy(() => import('pages1/apps/CRM/Projects'));
const CRMManagement = React.lazy(() => import('pages1/apps/CRM/Management'));
const CRMClients = React.lazy(() => import('pages1/apps/CRM/Clients'));
const CRMOrderList = React.lazy(() => import('pages1/apps/CRM/OrderList'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('pages1/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('pages1/apps/Ecommerce/ProductDetails'));
// const Orders = React.lazy(() => import('pages1/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('pages1/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('pages1/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('pages1/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('pages1/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('pages1/apps/Ecommerce/Sellers'));

// - email
const Inbox = React.lazy(() => import('pages1/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('pages1/apps/Email/Detail'));

// - social
const SocialFeed = React.lazy(() => import('pages1/apps/SocialFeed/'));

// - tasks
const TaskList = React.lazy(() => import('pages1/apps/Tasks/List/'));
const TaskDetails = React.lazy(() => import('pages1/apps/Tasks/Details'));
const Kanban = React.lazy(() => import('pages1/apps/Tasks/Board/'));
// - file
const FileManager = React.lazy(() => import('pages1/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('pages1/profile'));
const Profile2 = React.lazy(() => import('pages1/profile2'));
const ErrorPageNotFound = React.lazy(() => import('pages1/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages1/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages1/error/ServerError'));

// - other
const Invoice = React.lazy(() => import('pages1/other/Invoice'));
const FAQ = React.lazy(() => import('pages1/other/FAQ'));
const Pricing = React.lazy(() => import('pages1/other/Pricing'));
const Maintenance = React.lazy(() => import('pages1/other/Maintenance'));
const Starter = React.lazy(() => import('pages1/other/Starter'));
const PreLoader = React.lazy(() => import('pages1/other/PreLoader/'));
const Timeline = React.lazy(() => import('pages1/other/Timeline'));

const Landing = React.lazy(() => import('pages1/landing/'));

// uikit
const Accordions = React.lazy(() => import('pages1/uikit/Accordions'));
const Alerts = React.lazy(() => import('pages1/uikit/Alerts'));
const Avatars = React.lazy(() => import('pages1/uikit/Avatars'));
const Badges = React.lazy(() => import('pages1/uikit/Badges'));
const Breadcrumbs = React.lazy(() => import('pages1/uikit/Breadcrumb'));
const Buttons = React.lazy(() => import('pages1/uikit/Buttons'));
const Cards = React.lazy(() => import('pages1/uikit/Cards'));
const Carousels = React.lazy(() => import('pages1/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('pages1/uikit/Dropdowns'));
const EmbedVideo = React.lazy(() => import('pages1/uikit/EmbedVideo'));
const Grid = React.lazy(() => import('pages1/uikit/Grid'));
const ListGroups = React.lazy(() => import('pages1/uikit/ListGroups'));
const Modals = React.lazy(() => import('pages1/uikit/Modals'));
const Notifications = React.lazy(() => import('pages1/uikit/Notifications'));
const Offcanvases = React.lazy(() => import('pages1/uikit/Offcanvas'));
const Placeholders = React.lazy(() => import('pages1/uikit/Placeholders'));
const Paginations = React.lazy(() => import('pages1/uikit/Paginations'));
const Popovers = React.lazy(() => import('pages1/uikit/Popovers'));
const Progress = React.lazy(() => import('pages1/uikit/Progress'));
const Ribbons = React.lazy(() => import('pages1/uikit/Ribbons'));
const Spinners = React.lazy(() => import('pages1/uikit/Spinners'));
const Tabs = React.lazy(() => import('pages1/uikit/Tabs'));
const Tooltips = React.lazy(() => import('pages1/uikit/Tooltips'));
const Typography = React.lazy(() => import('pages1/uikit/Typography'));
const DragDrop = React.lazy(() => import('pages1/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('pages1/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('pages1/uikit/Ratings'));

// icons
const Dripicons = React.lazy(() => import('pages1/icons/Dripicons'));
const MDIIcons = React.lazy(() => import('pages1/icons/MDIIcons'));
const Unicons = React.lazy(() => import('pages1/icons/Unicons'));

// forms
const BasicForms = React.lazy(() => import('pages1/forms/Basic'));
const FormAdvanced = React.lazy(() => import('pages1/forms/Advanced'));
const FormValidation = React.lazy(() => import('pages1/forms/Validation'));
const FormWizard = React.lazy(() => import('pages1/forms/Wizard'));
const FileUpload = React.lazy(() => import('pages1/forms/FileUpload'));
const Editors = React.lazy(() => import('pages1/forms/Editors'));

// charts
const ApexChart = React.lazy(() => import('pages1/charts/Apex'));
const ChartJs = React.lazy(() => import('pages1/charts/ChartJs'));

// tables
const BasicTables = React.lazy(() => import('pages1/tables/Basic'));
const AdvancedTables = React.lazy(() => import('pages1/tables/Advanced'));

// widgets
const Widgets = React.lazy(() => import('pages1/uikit/Widgets'));

// maps
const GoogleMaps = React.lazy(() => import('pages1/maps/GoogleMaps'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => {
    useSelector(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useCallback(
            memoize((state: any) => ({
                user: state.Auth.user,
            })),
            []
        )
    );

    return <Suspense fallback={loading()}>
        <Component />
    </Suspense>
}

const AllRoutes = () => {

    const { layout } = useSelector(memoize((state: any) => ({
        layout: state.Layout,
    })));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                // layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };


    let Layout = getLayout();

    // @ts-ignore
    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgotPassword} /> },
                        { path: 'reset-password/:token', element: <LoadComponent component={ResetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
                {
                    path: 'oauthcallback',
                    children: [
                        { path: 'google', element: <LoadComponent component={GoogleCallback} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                // {
                //     path: 'error-500',
                //     element: <LoadComponent component={ServerError} />,
                // },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                // {
                //     path: 'landing',
                //     element: <LoadComponent component={Landing} />,
                // },
                {
                    path: 'verify-email/:token',
                    element: <LoadComponent component={VerifyEmail}/>,
                },
                {
                    path: 'access-denied',
                    element: <LoadComponent component={AccessDenied}/>,
                },
                {
                    path: 'session-expired',
                    element: <LoadComponent component={SessionExpired}/>,
                },
                {
                    path: '/deposits/:id/verify',
                    element: <LoadComponent component={VerifyDeposit} />,
                },
            ],
        },
        {
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: '/access-denied',
                    element: <LoadComponent component={AccessDenied} />,
                },
            ]
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute permissions={['Modify System Settings']} component={Layout} />,
            children: [
                {
                    path: 'settings',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Setting} />,
                        },
                        {
                            path: 'providers',
                            element: <LoadComponent component={Providers} />,
                        },
                        {
                            path: 'offline-deposits',
                            element: <LoadComponent component={OfflineDepositsSetting} />,
                        },
                        {
                            path: 'gateway-deposits',
                            element: <LoadComponent component={GatewayDepositsSetting} />,
                        },
                        {
                            path: 'providers/:id/edit',
                            element: <LoadComponent component={EditProvider} />,
                        },
                        {
                            path: 'orders',
                            element: <LoadComponent component={SettingOrders} />,
                        },
                        {
                            path: 'premium-account',
                            element: <LoadComponent component={SettingPremiumAccount} />,
                        },
                    ]
                }
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Dashboard"]} component={Layout}/>,
            children: [
                {
                    path: '/dashboard',
                    element: <LoadComponent component={Dashboard} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Users"]} component={Layout}/>,
            children: [
                {
                    path: '/users',
                    element: <LoadComponent component={Users} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View All Logs"]} component={Layout}/>,
            children: [
                {
                    path: '/users/logs',
                    element: <LoadComponent component={Logs} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Merchant Setting"]} component={Layout}/>,
            children: [
                {
                    path: '/merchants/:id/setting',
                    element: <LoadComponent component={EditMerchantSettings} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create User"]} component={Layout}/>,
            children: [
                {
                    path: '/users/create',
                    element: <LoadComponent component={NewUser} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View User"]} component={Layout}/>,
            children: [
                {
                    path: '/users/:id',
                    element: <LoadComponent component={ViewUser} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit User", "Edit Any User"]} component={Layout}/>,
            children: [
                {
                    path: '/users/:id/edit',
                    element: <LoadComponent component={EditUser} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Roles"]} component={Layout}/>,
            children: [
                {
                    path: '/roles',
                    element: <LoadComponent component={Roles} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Role"]} component={Layout}/>,
            children: [
                {
                    path: '/roles/create',
                    element: <LoadComponent component={NewRole} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Role"]} component={Layout}/>,
            children: [
                {
                    path: '/roles/:id/edit',
                    element: <LoadComponent component={EditRole} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Permissions"]} component={Layout}/>,
            children: [
                {
                    path: '/permissions',
                    element: <LoadComponent component={Permissions} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/my-account',
                    element: <LoadComponent component={MyAccount} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/my-account/edit',
                    element: <LoadComponent component={EditUserSetting} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/my-account/personal-detail/edit',
                    element: <LoadComponent component={EditPersonalDetail} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/profile/upload-image',
                    element: <LoadComponent component={UploadProfileImage} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/password/change',
                    element: <LoadComponent component={ChangePassword} />,
                },
            ]
        },

        // doneeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/personal-details',
                    element: <LoadComponent component={VerificationPersonalDetails} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/:userId/management',
                    element: <LoadComponent component={ManageVerifications} />,
                },
            ]
        },
        // doneeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail","Edit Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/personal-details/:id/edit',
                    element: <LoadComponent component={VerificationPersonalDetail} />,
                },
            ]
        },
        // doneeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/mobile-numbers',
                    element: <LoadComponent component={VerificationMobileNumbers} />,
                },
            ]
        },
        // doneeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail", "Edit Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/mobile-numbers/:id/send',
                    element: <LoadComponent component={MobileVerification} />,
                },
            ]
        },
        // {
        //     path: '/',
        //     element: <PrivateRoute permissions={["Approve Personal Detail", "Edit Personal Detail"]} component={Layout}/>,
        //     children: [
        //         {
        //             path: '/verification/mobile-numbers/:id/verify',
        //             element: <LoadComponent component={VerifyOtp} />,
        //         },
        //     ]
        // },

        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail", "Edit Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/bank-cards',
                    element: <LoadComponent component={VerificationBankCards} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail", "Edit Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/bank-cards/create',
                    element: <LoadComponent component={VRCreateBankCard} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail", "Edit Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/bank-cards/:id/edit',
                    element: <LoadComponent component={EditBankCard} />,
                },
            ]
        },


        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/terms-and-conditions',
                    element: <LoadComponent component={TermsAndConditions} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/terms-and-conditions/create',
                    element: <LoadComponent component={CreateTermAndCondition} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/terms-and-conditions/upload',
                    element: <LoadComponent component={UploadTermAndCondition} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Personal Detail"]} component={Layout}/>,
            children: [
                {
                    path: '/verification/terms-and-conditions/:id/check',
                    element: <LoadComponent component={CheckTermAndCondition} />,
                },
            ]
        },

        {
            path: '/',
            element: <PrivateRoute permissions={["View Deposits", "View Withdraws"]} component={Layout}/>,
            children: [
                {
                    path: '/deposits-withdraws',
                    element: <LoadComponent component={DepositsWithdraws} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create IRR Deposit"]} component={Layout}/>,
            children: [
                {
                    path: '/deposits/create',
                    element: <LoadComponent component={CreateDeposit} />,
                },
                {
                    path: '/deposits/redirect/:to',
                    element: <LoadComponent component={Redirecting} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Manual Deposit"]} component={Layout}/>,
            children: [
                {
                    path: '/deposits/create/manual',
                    element: <LoadComponent component={CreateManualDeposit} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Deposit"]} component={Layout}/>,
            children: [
                {
                    path: '/deposits/:id/invoice',
                    element: <LoadComponent component={DepositInvoice} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Transfers"]} component={Layout}/>,
            children: [
                {
                    path: '/transfers',
                    element: <LoadComponent component={Transfers} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Transfer Transactions"]} component={Layout}/>,
            children: [
                {
                    path: '/transfer-transactions',
                    element: <LoadComponent component={TransferTransactions} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Transfer"]} component={Layout}/>,
            children: [
                {
                    path: '/transfers/create',
                    element: <LoadComponent component={CreateTransfer} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Transfer"]} component={Layout}/>,
            children: [
                {
                    path: '/transfers/:id/invoice',
                    element: <LoadComponent component={TransferInvoice} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Exchanges"]} component={Layout}/>,
            children: [
                {
                    path: '/exchanges',
                    element: <LoadComponent component={Exchanges} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Exchange Transactions"]} component={Layout}/>,
            children: [
                {
                    path: '/exchange-transactions',
                    element: <LoadComponent component={ExchangeTransactions} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Exchange"]} component={Layout}/>,
            children: [
                {
                    path: '/exchanges/create',
                    element: <LoadComponent component={CreateExchange} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Exchange"]} component={Layout}/>,
            children: [
                {
                    path: '/exchanges/:id/invoice',
                    element: <LoadComponent component={ExchangeInvoice} />,
                },
            ]
        },



        {
            path: '/',
            element: <PrivateRoute permissions={["Create IRR Withdraw"]} component={Layout}/>,
            children: [
                {
                    path: '/withdraws/create',
                    element: <LoadComponent component={CreateWithdraw} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Manual Withdraw"]} component={Layout}/>,
            children: [
                {
                    path: '/withdraws/create/manual',
                    element: <LoadComponent component={CreateManualWithdraw} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve IRR Withdraw"]} component={Layout}/>,
            children: [
                {
                    path: '/withdraws/:id/approve',
                    element: <LoadComponent component={ApproveIRRWithdraw} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Withdraw"]} component={Layout}/>,
            children: [
                {
                    path: '/withdraws/:id/invoice',
                    element: <LoadComponent component={WithdrawInvoice} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View All Withdraws"]} component={Layout}/>,
            children: [
                {
                    path: '/withdraws',
                    element: <LoadComponent component={Withdraws} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View All Deposits"]} component={Layout}/>,
            children: [
                {
                    path: '/deposits',
                    element: <LoadComponent component={PendingDeposits} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View All Offline Deposits", "View Offline Deposits"]} component={Layout}/>,
            children: [
                {
                    path: '/offline-deposits',
                    element: <LoadComponent component={OfflineDeposits} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Approve Offline Deposit"]} component={Layout}/>,
            children: [
                {
                    path: '/offline-deposits/:id/approve',
                    element: <LoadComponent component={ApproveOfflineDeposit} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View IBANs"]} component={Layout}/>,
            children: [
                {
                    path: '/ibans',
                    element: <LoadComponent component={IBANs} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create IBAN"]} component={Layout}/>,
            children: [
                {
                    path: '/ibans/create',
                    element: <LoadComponent component={CreateIBAN} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/products',
                    element: <LoadComponent component={Products} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/products/:id/edit',
                    element: <LoadComponent component={EditProducts} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/products/create',
                    element: <LoadComponent component={CreateProduct} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/gift-cards',
                    element: <LoadComponent component={GiftCards} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/gift-cards/buy',
                    element: <LoadComponent component={BuyGiftCard} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Modify System Settings"]} component={Layout}/>,
            children: [
                {
                    path: 'rates',
                    children: [
                        {
                            path: ':symbol',
                            element: <LoadComponent component={Rates} />,
                        },
                    ]
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Modify System Settings"]} component={Layout}/>,
            children: [
                {
                    path: 'conversion-rates',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={ConversionRates} />,
                        },
                        {
                            path: 'create',
                            element: <LoadComponent component={CreateConversionRate} />,
                        },
                        {
                            path: ':id/edit',
                            element: <LoadComponent component={EditConversionRate} />,
                        },
                    ]
                },
            ]
        },























        {
            path: '/',
            element: <PrivateRoute permissions={["View Payments"]} component={Layout}/>,
            children: [
                {
                    path: '/payments',
                    element: <LoadComponent component={Payments} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Payment"]} component={Layout}/>,
            children: [
                {
                    path: '/payments/:id/invoice',
                    element: <LoadComponent component={PaymentInvoice} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Payment Transactions"]} component={Layout}/>,
            children: [
                {
                    path: '/payment-transactions',
                    element: <LoadComponent component={PaymentTransactions} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Transactions"]} component={Layout}/>,
            children: [
                {
                    path: '/transactions',
                    element: <LoadComponent component={Transactions} />,
                },
            ]
        },


        {
            path: '/',
            element: <PrivateRoute permissions={["View USD Wallet", "View IRR Wallet"]} component={Layout}/>,
            children: [
                {
                    path: '/wallets',
                    element: <LoadComponent component={Wallets} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders',
                    element: <LoadComponent component={Orders} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Order"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/create',
                    element: <LoadComponent component={NewOrder} />,
                },
            ]
        },
        // checked
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ci/:hashedId',
                    element: <LoadComponent component={CashIncomeOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ip/:hashedId',
                    element: <LoadComponent component={IntlPaymentOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ee/:hashedId',
                    element: <LoadComponent component={EduExpenseOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ps/:hashedId',
                    element: <LoadComponent component={PremiumSubscriptionOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/mt/:hashedId',
                    element: <LoadComponent component={IntlMoneyTransferOrder} />,
                },
            ]
        },
        // checked
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any Order"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/:hashedId/deposit',
                    element: <LoadComponent component={OrderDeposit} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any Order"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/:hashedId/bill/create',
                    element: <LoadComponent component={CreateOrderBill} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/transactions',
                    element: <LoadComponent component={OrderTransactions} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/bills',
                    element: <LoadComponent component={OrderBills} />,
                },
            ]
        },
        // checked
        {
            path: '/',
            element: <PrivateRoute permissions={["View Orders", "View All Orders"]} component={Layout}/>,
            children: [
                {
                    path: '/bill/:hashedId',
                    element: <LoadComponent component={Bill} />,
                },
            ]
        },
        // checked
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any Order"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/:hashedId/note/create',
                    element: <LoadComponent component={CreateOrderNote} />,
                },
            ]
        },
        // checked
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any Cash Income"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ci/:hashedId/edit',
                    element: <LoadComponent component={EditCashIncomeOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any International Payment"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ip/:hashedId/edit',
                    element: <LoadComponent component={EditIntlPaymentOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any Edu Expense Payment"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ee/:hashedId/edit',
                    element: <LoadComponent component={EditEduExpensePaymentOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any Premium Subscription"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/ps/:hashedId/edit',
                    element: <LoadComponent component={EditPremiumSubscriptionOrder} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Edit Any Intl Money Transfer"]} component={Layout}/>,
            children: [
                {
                    path: '/orders/mt/:hashedId/edit',
                    element: <LoadComponent component={EditIntlMoneyTransferOrder} />,
                },
            ]
        },

        {
            path: '/',
            element: <PrivateRoute permissions={["View Tokens"]} component={Layout}/>,
            children: [
                {
                    path: '/tokens',
                    element: <LoadComponent component={Tokens} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Token"]} component={Layout}/>,
            children: [
                {
                    path: '/tokens/create',
                    element: <LoadComponent component={CreateToken} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Tokens"]} component={Layout}/>,
            children: [
                {
                    path: '/trusted-ips',
                    element: <LoadComponent component={IPs} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Token"]} component={Layout}/>,
            children: [
                {
                    path: '/trusted-ips/create',
                    element: <LoadComponent component={CreateIp} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Tokens"]} component={Layout}/>,
            children: [
                {
                    path: '/resources',
                    element: <LoadComponent component={Resources} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["View Export Requests", "View All Export Requests"]} component={Layout}/>,
            children: [
                {
                    path: '/export-requests',
                    element: <LoadComponent component={ExportRequests} />,
                },
            ]
        },

        {
            path: '/',
            element: <PrivateRoute permissions={["View Bank Cards"]} component={Layout}/>,
            children: [
                {
                    path: '/bank-cards',
                    element: <LoadComponent component={BankCards} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["Create Bank Card"]} component={Layout}/>,
            children: [
                {
                    path: '/bank-cards/create',
                    element: <LoadComponent component={CreateBankCard} />,
                },
            ]
        },

        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/2fa/enable',
                    element: <LoadComponent component={Enable2Fa} />,
                },
            ]
        },
        {
            path: '/',
            element: <PrivateRoute permissions={["*"]} component={Layout}/>,
            children: [
                {
                    path: '/2fa/disable',
                    element: <LoadComponent component={Disable2Fa} />,
                },
            ]
        },




        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute permissions={['*']} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'analytics',
                            element: <LoadComponent component={AnalyticsDashboard} />,
                        },
                        {
                            path: 'ecommerce',
                            element: <LoadComponent component={EcommerceDashboard} />,
                        },
                        {
                            path: 'project',
                            element: <LoadComponent component={ProjectDashboard} />,
                        },
                        {
                            path: 'e-wallet1',
                            element: <LoadComponent component={EWalletDashboard} />,
                        },
                    ],
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'chat',
                            element: <LoadComponent component={ChatApp} />,
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'dashboard',
                                    element: <LoadComponent component={CRMDashboard} />,
                                },
                                {
                                    path: 'projects',
                                    element: <LoadComponent component={CRMProjects} />,
                                },
                                {
                                    path: 'management',
                                    element: <LoadComponent component={CRMManagement} />,
                                },
                                {
                                    path: 'clients',
                                    element: <LoadComponent component={CRMClients} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={CRMOrderList} />,
                                },
                            ],
                        },
                        {
                            path: 'ecommerce',
                            children: [
                                {
                                    path: 'products',
                                    element: <LoadComponent component={EcommerceProducts} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProductDetails} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={Orders} />,
                                },
                                {
                                    path: 'order/details',
                                    element: <LoadComponent component={OrderDetails} />,
                                },
                                {
                                    path: 'customers',
                                    element: <LoadComponent component={Customers} />,
                                },
                                {
                                    path: 'shopping-cart',
                                    element: <LoadComponent component={Cart} />,
                                },
                                {
                                    path: 'checkout',
                                    element: <LoadComponent component={Checkout} />,
                                },
                                {
                                    path: 'sellers',
                                    element: <LoadComponent component={Sellers} />,
                                },
                            ],
                        },
                        {
                            path: 'email',
                            children: [
                                {
                                    path: 'inbox',
                                    element: <LoadComponent component={Inbox} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={EmailDetail} />,
                                },
                            ],
                        },
                        {
                            path: 'tasks',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={TaskList} />,
                                },
                                {
                                    path: 'kanban',
                                    element: <LoadComponent component={Kanban} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={TaskDetails} />,
                                },
                            ],
                        },

                        {
                            path: 'projects',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={ProjectList} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProjectDetail} />,
                                },
                                {
                                    path: 'gantt',
                                    element: <LoadComponent component={ProjectGannt} />,
                                },
                                {
                                    path: 'new',
                                    element: <LoadComponent component={ProjectForm} />,
                                },
                            ],
                        },
                        {
                            path: 'social',
                            element: <LoadComponent component={SocialFeed} />,
                        },
                        {
                            path: 'file',
                            element: <LoadComponent component={FileManager} />,
                        },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'starter',
                            element: <LoadComponent component={Starter} />,
                        },
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile} />,
                        },
                        {
                            path: 'profile2',
                            element: <LoadComponent component={Profile2} />,
                        },
                        {
                            path: 'pricing',
                            element: <LoadComponent component={Pricing} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                        {
                            path: 'timeline',
                            element: <LoadComponent component={Timeline} />,
                        },
                        {
                            path: 'invoice',
                            element: <LoadComponent component={Invoice} />,
                        },
                        {
                            path: 'faq',
                            element: <LoadComponent component={FAQ} />,
                        },
                        {
                            path: 'preloader',
                            element: <LoadComponent component={PreLoader} />,
                        },
                    ],
                },
                {
                    path: 'ui',
                    children: [
                        {
                            path: 'base-ui',
                            children: [
                                {
                                    path: 'accordions',
                                    element: <LoadComponent component={Accordions} />,
                                },
                                {
                                    path: 'alerts',
                                    element: <LoadComponent component={Alerts} />,
                                },
                                {
                                    path: 'avatars',
                                    element: <LoadComponent component={Avatars} />,
                                },
                                {
                                    path: 'badges',
                                    element: <LoadComponent component={Badges} />,
                                },
                                {
                                    path: 'breadcrumb',
                                    element: <LoadComponent component={Breadcrumbs} />,
                                },
                                {
                                    path: 'buttons',
                                    element: <LoadComponent component={Buttons} />,
                                },
                                {
                                    path: 'cards',
                                    element: <LoadComponent component={Cards} />,
                                },
                                {
                                    path: 'carousel',
                                    element: <LoadComponent component={Carousels} />,
                                },
                                {
                                    path: 'dropdowns',
                                    element: <LoadComponent component={Dropdowns} />,
                                },
                                {
                                    path: 'embedvideo',
                                    element: <LoadComponent component={EmbedVideo} />,
                                },
                                {
                                    path: 'grid',
                                    element: <LoadComponent component={Grid} />,
                                },
                                {
                                    path: 'listgroups',
                                    element: <LoadComponent component={ListGroups} />,
                                },
                                {
                                    path: 'modals',
                                    element: <LoadComponent component={Modals} />,
                                },
                                {
                                    path: 'notifications',
                                    element: <LoadComponent component={Notifications} />,
                                },
                                {
                                    path: 'offcanvas',
                                    element: <LoadComponent component={Offcanvases} />,
                                },
                                {
                                    path: 'placeholders',
                                    element: <LoadComponent component={Placeholders} />,
                                },
                                {
                                    path: 'paginations',
                                    element: <LoadComponent component={Paginations} />,
                                },
                                {
                                    path: 'popovers',
                                    element: <LoadComponent component={Popovers} />,
                                },
                                {
                                    path: 'progress',
                                    element: <LoadComponent component={Progress} />,
                                },
                                {
                                    path: 'ribbons',
                                    element: <LoadComponent component={Ribbons} />,
                                },
                                {
                                    path: 'spinners',
                                    element: <LoadComponent component={Spinners} />,
                                },
                                {
                                    path: 'tabs',
                                    element: <LoadComponent component={Tabs} />,
                                },
                                {
                                    path: 'tooltips',
                                    element: <LoadComponent component={Tooltips} />,
                                },
                                {
                                    path: 'typography',
                                    element: <LoadComponent component={Typography} />,
                                },
                            ],
                        },
                        {
                            path: 'widgets',
                            element: <LoadComponent component={Widgets} />,
                        },
                        {
                            path: 'extended',
                            children: [
                                {
                                    path: 'dragdrop',
                                    element: <LoadComponent component={DragDrop} />,
                                },
                                {
                                    path: 'rangesliders',
                                    element: <LoadComponent component={RangeSliders} />,
                                },
                                {
                                    path: 'ratings',
                                    element: <LoadComponent component={Ratings} />,
                                },
                            ],
                        },
                        {
                            path: 'icons',
                            children: [
                                {
                                    path: 'unicons',
                                    element: <LoadComponent component={Unicons} />,
                                },
                                {
                                    path: 'mdi',
                                    element: <LoadComponent component={MDIIcons} />,
                                },
                                {
                                    path: 'dripicons',
                                    element: <LoadComponent component={Dripicons} />,
                                },
                            ],
                        },
                        {
                            path: 'forms',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicForms} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={FormAdvanced} />,
                                },
                                {
                                    path: 'validation',
                                    element: <LoadComponent component={FormValidation} />,
                                },
                                {
                                    path: 'wizard',
                                    element: <LoadComponent component={FormWizard} />,
                                },
                                {
                                    path: 'upload',
                                    element: <LoadComponent component={FileUpload} />,
                                },
                                {
                                    path: 'editors',
                                    element: <LoadComponent component={Editors} />,
                                },
                            ],
                        },
                        {
                            path: 'tables',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicTables} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={AdvancedTables} />,
                                },
                            ],
                        },
                        {
                            path: 'charts',
                            children: [
                                {
                                    path: 'apex',
                                    element: <LoadComponent component={ApexChart} />,
                                },
                                {
                                    path: 'chartjs',
                                    element: <LoadComponent component={ChartJs} />,
                                },
                            ],
                        },
                        {
                            path: 'maps',
                            children: [
                                {
                                    path: 'googlemaps',
                                    element: <LoadComponent component={GoogleMaps} />,
                                }
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };

import { AuthActionTypes } from './constants';

export type AuthActionType = {
    type:
        | AuthActionTypes.API_RESPONSE_SUCCESS
        | AuthActionTypes.API_RESPONSE_ERROR
        | AuthActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | AuthActionTypes.FORGOT_PASSWORD
        | AuthActionTypes.FORGOT_PASSWORD_CHANGE
        | AuthActionTypes.RESET_PASSWORD
        | AuthActionTypes.LOGIN_USER
        | AuthActionTypes.LOGOUT_USER
        | AuthActionTypes.RESET
        | AuthActionTypes.SIGNUP_USER
        | AuthActionTypes.SOCIAL_CALLBACK_URL
        | AuthActionTypes.GOOGLE_SIGN_IN
        | AuthActionTypes.SET_ERROR
        | AuthActionTypes.REFRESH_TOKEN
        | AuthActionTypes.START_REFRESH_TOKEN_LOOP
        | AuthActionTypes.END_REFRESH_TOKEN_LOOP;
    payload: {} | string;
};

type User = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

class AuthAction {

    apiResponseSuccess = (actionType: string, data: User | {}): AuthActionType => ({
        type: AuthActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string): AuthActionType => ({
        type: AuthActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    });
// common validation error
    apiResponseValidationErrors = (actionType: string, validationErrors: []): AuthActionType => ({
        type: AuthActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors },
    });

    loginUser = (username: string, password: string, twoFaOtp: string, recaptchaToken: string): AuthActionType => ({
        type: AuthActionTypes.LOGIN_USER,
        payload: { username, password, twoFaOtp, recaptchaToken },
    });

    logoutUser = (): AuthActionType => ({
        type: AuthActionTypes.LOGOUT_USER,
        payload: {},
    });

    signupUser = (firstname: string, lastname: string, email: string, password: string, recaptchaToken:string, language: string, ref: string): AuthActionType => ({
        type: AuthActionTypes.SIGNUP_USER,
        payload: { firstname, lastname, email, password, recaptchaToken, language, ref },
    });

    forgotPassword = (username: string, recaptchaToken: string): AuthActionType => ({
        type: AuthActionTypes.FORGOT_PASSWORD,
        payload: { username, recaptchaToken },
    });

    resetPassword = (token: string, password: string, recaptchaToken: string): AuthActionType => ({
        type: AuthActionTypes.RESET_PASSWORD,
        payload: { token, password, recaptchaToken },
    });

    resetAuth = (): AuthActionType => ({
        type: AuthActionTypes.RESET,
        payload: {},
    });

    getSocialCallbackUrls = (): AuthActionType => ({
        type: AuthActionTypes.SOCIAL_CALLBACK_URL,
        payload: {},
    });

    googleOauthSignin = (searchParameters: any): AuthActionType => ({
        type: AuthActionTypes.GOOGLE_SIGN_IN,
        payload: { searchParameters },
    });

    setAuthError = (error: any): AuthActionType => ({
        type: AuthActionTypes.SET_ERROR,
        payload: error,
    });

    refreshToken = (): AuthActionType => ({
        type: AuthActionTypes.REFRESH_TOKEN,
        payload: {},
    });

    startRefreshTokenLoop = (): AuthActionType => ({
        type: AuthActionTypes.START_REFRESH_TOKEN_LOOP,
        payload: {},
    });

    endRefreshTokenLoop = (): AuthActionType => ({
        type: AuthActionTypes.END_REFRESH_TOKEN_LOOP,
        payload: {},
    });
}

const authAction = new AuthAction();
export { authAction };


import {GatewayDepositSettingActionTypes} from './constants';

export type GatewayDepositSettingActionType = {
    type:
        | GatewayDepositSettingActionTypes.API_RESPONSE_SUCCESS
        | GatewayDepositSettingActionTypes.API_RESPONSE_ERROR
        | GatewayDepositSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS
        | GatewayDepositSettingActionTypes.RESET
        | GatewayDepositSettingActionTypes.GET_OPTIONS
        | GatewayDepositSettingActionTypes.UPDATE_SETTING;

    payload: {} | string;
};

class GatewayDepositSettingActions {

    apiResponseSuccess = (actionType: string, data: any): GatewayDepositSettingActionType => ({
        type: GatewayDepositSettingActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    });

    apiResponseError = (actionType: string, error: string, trackId: string = ""): GatewayDepositSettingActionType => ({
        type: GatewayDepositSettingActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error, trackId },
    });

    apiResponseValidationErrors = (actionType: string, validationErrors: [], trackId: string = ""): GatewayDepositSettingActionType => ({
        type: GatewayDepositSettingActionTypes.API_RESPONSE_VALIDATION_ERRORS,
        payload: { actionType, validationErrors, trackId },
    });

    reset = (): GatewayDepositSettingActionType => ({
        type: GatewayDepositSettingActionTypes.RESET,
        payload: {},
    });

    updateSetting = (data: any, trackId: string): GatewayDepositSettingActionType => ({
        type: GatewayDepositSettingActionTypes.UPDATE_SETTING,
        payload: { data, trackId },
    });

    getOptions = (data: any): GatewayDepositSettingActionType => ({
        type: GatewayDepositSettingActionTypes.GET_OPTIONS,
        payload: { data },
    });
}

const gatewayDepositSettingActions = new GatewayDepositSettingActions();
export { gatewayDepositSettingActions };
